import { Buttons } from "@/ui/Buttons";
import { Footer } from "@/ui/Footer";
import { Header } from "@/ui/Header";

import cn from "classnames";
import classes from "./styles.module.scss";

export default function ({ id, children }) {
  return (
    <>
      <Header />
      <main id={id}>{children}</main>
      <Footer />
      <Buttons
        className={cn(classes.buttons)}
        buttons={[
          {
            label: "Job Search",
            url: "/jobs/",
            icon: <img src="/assets/Search.svg" width="12" height="12" alt="" />,
            variant: "gradient-green",
          },
          {
            label: "Contact us",
            url: "/enquiry/",
            icon: <img src="/assets/Chat.svg" width="12" height="12" alt="" />,
            variant: "gradient-cyan",
          },
        ]}
      />
    </>
  );
}
